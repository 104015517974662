import React, { useState } from "react";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import { Link as ScrollLink, Element } from "react-scroll";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FaWhatsapp, FaEnvelope, FaInstagram } from "react-icons/fa";
import "./styles.css";

export default function App() {
  const [language, setLanguage] = useState("en");

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "en" ? "es" : "en"));
  };

  return (
    <ParallaxProvider>
      <header>
        <nav className="navbar">
          <ScrollLink to="who-we-are" smooth={true} className="nav-link">{language === "en" ? "Who We Are" : "Quiénes Somos"}</ScrollLink>
          <ScrollLink to="services" smooth={true} className="nav-link">{language === "en" ? "Services" : "Servicios"}</ScrollLink>
          <ScrollLink to="vision" smooth={true} className="nav-link">{language === "en" ? "Vision" : "Visión"}</ScrollLink>
          <ScrollLink to="about-us" smooth={true} className="nav-link">{language === "en" ? "About Us" : "Sobre Nosotros"}</ScrollLink>
          <ScrollLink to="contact" smooth={true} className="nav-link">{language === "en" ? "Contact" : "Contacto"}</ScrollLink>
        </nav>
        <ParallaxBanner
          className="banner"
          layers={[
            { image: "bg-1.jpg", translateY: [0, 50], shouldAlwaysCompleteAnimation: true, expanded: false },
            { image: "bg-2.png", translateY: [5, 45], shouldAlwaysCompleteAnimation: true, expanded: false },
            { image: "bg-3.png", translateY: [10, 30], shouldAlwaysCompleteAnimation: true, expanded: false },
            { image: "bg-4.png", translateY: [15, 25], shouldAlwaysCompleteAnimation: true, expanded: false },
            { image: "bg-5.png", translateY: [20, 20], shouldAlwaysCompleteAnimation: true, expanded: false }
          ]}
        >
          <div className="header-content">
            <img src="/logo.png" alt="Cordillera Logo" className="logo" />
            <h1>{language === "en" ? "Where Expertise Meets Insight" : "Donde la Experiencia se Encuentra con la Visión"}</h1>
            <p>{language === "en" ? "Excellence in Strategic Business Consulting" : "Excelencia en Consultoría Estratégica Empresarial"}</p>
          </div>
        </ParallaxBanner>
        <button className="language-toggle" onClick={toggleLanguage}>
          {language === "en" ? "Español" : "English"}
        </button>
      </header>

      <main>
        <TransitionGroup>
          <CSSTransition timeout={500} classNames="fade" key="who-we-are">
            <Element name="who-we-are" className="content-section">
              <section>
                <h2>{language === "en" ? "Who We Are" : "Quiénes Somos"}</h2>
                <p>{language === "en" ?
                  "We are a team of dedicated professionals focused on providing strategic advice and operational assistance to businesses and organizations. Our expertise includes strategic planning, product and service management, innovation, and much more." :
                  "Somos un equipo de profesionales dedicados a proporcionar asesoramiento estratégico y asistencia operativa a empresas y organizaciones. Nuestra experiencia incluye planificación estratégica, gestión de productos y servicios, innovación y mucho más."}
                </p>
                <h3>{language === "en" ? "Our Team" : "Nuestro Equipo"}</h3>
                <p>{language === "en" ?
                  "We are a diverse group of professionals working together to generate added value for our clients." :
                  "Somos un equipo de profesionales de diferentes áreas del conocimiento trabajando en conjunto para generar valor agregado."}
                </p>
              </section>
            </Element>
          </CSSTransition>

          <CSSTransition timeout={500} classNames="fade" key="services">
            <Element name="services" className="content-section">
              <section>
                <h2>{language === "en" ? "Services" : "Servicios"}</h2>
                <p>{language === "en" ?
                  "Cordillera Consoulting S.A.S offers a wide range of services to support your business needs. From strategic planning to human resources and artificial intelligence, we have the expertise to elevate your operations." :
                  "Cordillera Consoulting S.A.S ofrece una amplia gama de servicios para satisfacer las necesidades de su negocio. Desde planificación estratégica hasta recursos humanos e inteligencia artificial, tenemos la experiencia para elevar sus operaciones."}
                </p>
              </section>
              <section>
                <h3>{language === "en" ? "Business Consulting" : "Consultoría Empresarial"}</h3>
                <p>{language === "en" ?
                  "Our core service is business consulting, where we provide guidance on strategic decision-making, marketing strategies, production planning, and much more to help you optimize your business operations." :
                  "Nuestro principal servicio es la consultoría empresarial, donde le orientamos en la toma de decisiones estratégicas, estrategias de marketing, planificación de la producción y mucho más para ayudarle a optimizar las operaciones de su empresa."}
                </p>
              </section>
              <section>
                <h3>{language === "en" ? "Innovative Solutions" : "Soluciones Innovadoras"}</h3>
                <p>{language === "en" ?
                  "We specialize in providing innovative solutions to complex problems. Our team of experts will work with you to develop customized strategies and solutions that meet your unique business needs." :
                  "Nos especializamos en ofrecer soluciones innovadoras a problemas complejos. Nuestro equipo de expertos trabajará con usted para desarrollar estrategias y soluciones personalizadas que satisfagan sus necesidades empresariales únicas."}
                </p>
              </section>
            </Element>
          </CSSTransition>

          <CSSTransition timeout={500} classNames="fade" key="vision">
            <Element name="vision" className="content-section">
              <section>
                <h2>{language === "en" ? "Vision" : "Visión"}</h2>
                <p>{language === "en" ?
                  "Our vision is to create a nexus of knowledge and analytics that not only delivers high-quality products but also promotes continuous professional development." :
                  "Imaginamos un nexo de conocimiento y analítica, que no solo entregue productos de calidad, sino que además fomente la formación profesional continua."}
                </p>
              </section>
            </Element>
          </CSSTransition>

          <CSSTransition timeout={500} classNames="fade" key="about-us">
            <Element name="about-us" className="content-section">
              <section>
                <h2>{language === "en" ? "About Us" : "Sobre Nosotros"}</h2>
                <p>{language === "en" ?
                  "At Cordillera, we're driven by one simple principle: to help businesses thrive. Our journey started with a spark of inspiration and a commitment to doing things differently." :
                  "En Cordillera nos mueve un principio muy sencillo: ayudar a las empresas a prosperar. Nuestro viaje comenzó con una chispa de inspiración y el compromiso de hacer las cosas de forma diferente."}
                </p>
              </section>
            </Element>
          </CSSTransition>

          <CSSTransition timeout={500} classNames="fade" key="contact">
            <Element name="contact" className="content-section">
              <section>
                <h2>{language === "en" ? "Contact" : "Contacto"}</h2>
                <p>{language === "en" ?
                  "Ready to take the next step? Contact us today!" :
                  "¿Listo para dar el siguiente paso? ¡Contáctanos hoy!"}
                </p>
                <div className="contact-buttons">
                  <a href="https://wa.me/+573127731249" target="_blank" rel="noopener noreferrer" className="contact-button whatsapp">
                    <FaWhatsapp /> WhatsApp
                  </a>
                  <a href="mailto:info@cordillera.com" className="contact-button email">
                    <FaEnvelope /> Email
                  </a>
                  <a href="https://www.instagram.com/cordillera" target="_blank" rel="noopener noreferrer" className="contact-button instagram">
                    <FaInstagram /> Instagram
                  </a>
                </div>
                <p>{language === "en" ?
                  "Phone: +573127731249" :
                  "Teléfono: +573127731249"}
                </p>
                <p>{language === "en" ?
                  "Website: www.cordilleraanalytics.com" :
                  "Página web: www.cordilleraanalytics.com"}
                </p>
                <p>{language === "en" ?
                  "Email: info@cordillera.com" :
                  "Correo electrónico: info@cordillera.com"}
                </p>
              </section>
            </Element>
          </CSSTransition>
        </TransitionGroup>
      </main>
    </ParallaxProvider>
  );
}
